import _ from 'lodash';

const parseIds = (id: string) => {
  return _.replace(id, ':', '/');
};

type Cached = { [identifier: string]: string };
const thumbnails: Cached = {};

export const getGameThumbnail = (identifier: string) => {
  const id = parseIds(identifier);
  let hit = _.get(thumbnails, id);
  if (!hit) {
    hit = `https://cdn.softswiss.net/i/s4/${id}.png`;
    _.set(thumbnails, [id], hit);
  }
  return hit;
};

const bgs: Cached = {};

export const getGameBackground = (identifier: string) => {
  const id = parseIds(identifier);
  let hit = _.get(bgs, id);

  if (!hit) {
    hit = `https://cdn.softswiss.net/i/s2/${id}.png`;
    _.set(bgs, id, hit);
  }
  return hit;
};

const pbgs: Cached = {};

export const getProviderBackground = (id: string) => {
  let hit = _.get(pbgs, id);

  if (!hit) {
    hit = `https://cdn.softswiss.net/logos/providers/white/${id}.svg`;
    _.set(pbgs, [id], hit);
  }
  return hit;
};
