import { Trans } from '@lingui/macro';
import { ProducerId } from 'src/utils/types/outcome';

export enum OddsFormat {
  Decimal = '1',
  Fraction = '2',
  American = '3',
}

export const getOddFormats = (): {
  value: OddsFormat;
  label: React.ReactNode;
}[] => [
  {
    label: <Trans id="left_nav.odd_format.decimal">Decimal</Trans>,
    value: OddsFormat.Decimal,
  },
  {
    label: <Trans id="left_nav.odd_format.fraction">Fraction</Trans>,
    value: OddsFormat.Fraction,
  },
  {
    label: <Trans id="left_nav.odd_format.american">American</Trans>,
    value: OddsFormat.American,
  },
];

export interface UserPrefer {
  oddsFormat: OddsFormat;
  /**
   * @deprecated Use `oddsChange` instead.
   */
  anyOdds: boolean;
  fiat: boolean;
  hideBalance: boolean;
}
export const INITIAL_USER_PREF: UserPrefer = {
  oddsFormat: OddsFormat.American,
  anyOdds: false,
  fiat: true,
  hideBalance: false,
};

export const SUPPORT_EMAIL = process.env.NEXT_PUBLIC_SUPPORT_EMAIL ?? '';

export const PRODUCER_WEIGHT: Record<number | string, number> = {
  [ProducerId.PREMATCH]: 10,
  [ProducerId.LIVE]: 20,
  [ProducerId.LDO]: 30,
};

export const FIAT_DP = 2;
export const CRYPTO_DP = 5;

export enum BrMarketStatus {
  ACTIVE = '1',
  STOPPED = '0',
  SUSPENDED = '-1',
  HANDED_OVER = '-2',
  SETTLED = '-3',
  CANCELLED = '-4',
}

export const MAX_NUMBER_OF_LEADERBOARD_ROWS = 7;

export const DEFAULT_COIN = 'usdt';

export const TERMS_AND_CONDITIONS_TAB = 'terms-and-conditions';

export const COUNTRY_HEADER = 'CF-IPCountry';

export const IP_HEADER = 'CF-Connecting-IP';

export const CITY_HEADER = 'CF-IPCity';

export const REGION_HEADER = 'CF-Region';

export const ACCOUNTS_PAYOUT_AND_BONUS_TAB = 'accounts-payouts-bonuses';
