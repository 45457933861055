import { Locale } from '@funkit/connect';
import { Trans } from '@lingui/macro';
import _ from 'lodash';
import { DEFAULT_LOCALE } from '../constants/keys';

export const displayUsername = (name: string) => {
  if (!name || name.toLocaleLowerCase() === 'hidden')
    return <Trans id="common.hidden">hidden</Trans>;

  return name;
};

// https://www.localeplanet.com/icu/es-419/index.html
const ICULocales: Record<string, string> = {
  en: 'en_GB',
  'pt-br': 'pt_BR',
  'fr-ca': 'fr_CA',
  'es-la': 'es_419',
};

export const getICULocale = (lang: string = DEFAULT_LOCALE) => {
  return _.get(ICULocales, [lang], lang);
};

export interface WithLocale {
  locale?: string;
}

const contentfulLocales: Record<string, string> = {
  en: 'en-US',
  'pt-br': 'pt-BR',
  'fr-ca': 'fr-CA',
  'es-la': 'es-419',
};

export const getContentfulLocale = <T extends WithLocale>(
  params: T,
): T & { locale: string } => {
  const locale = params?.locale
    ? contentfulLocales[params.locale] ?? params.locale
    : contentfulLocales[DEFAULT_LOCALE];

  return { ...params, locale };
};

// https://github.com/moment/moment/blob/develop/locale/es-mx.js
const momentLocales: Record<string, string> = {
  en: 'en-gb',
  'pt-br': 'pt-br',
  'fr-ca': 'fr-ca',
  'es-la': 'es-mx',
};

export const getMomentLocale = (lang: string = DEFAULT_LOCALE) => {
  return _.get(momentLocales, [lang], lang);
};

const funkitLocales: Record<string, string> = {
  en: 'en',
  'pt-br': 'pt-BR',
  'fr-ca': 'fr',
  'es-la': 'es-419',
};

export const getFunkitLocale = (lang: string = DEFAULT_LOCALE) => {
  return _.get(funkitLocales, [lang], lang) as Locale;
};

// https://widgets.sir.sportradar.com/docs/Languages.html
const BR_LOCALES = {
  'pt-br': 'br',
  'fr-ca': 'fr',
  en: 'en',
  'es-la': 'es_la',
};

export const getBRLocale = (locale: string = DEFAULT_LOCALE) => {
  return _.get(BR_LOCALES, [locale], locale);
};
