import _ from 'lodash';
import APE from 'src/assets/icons/coins/ape.svg?url';
import BNB from 'src/assets/icons/coins/bnb.svg?url';
import BRL from 'src/assets/icons/coins/brl.svg?url';
import BTC from 'src/assets/icons/coins/btc.svg?url';
import CAD from 'src/assets/icons/coins/cad.svg?url';
import ETH from 'src/assets/icons/coins/eth.svg?url';
import KCS from 'src/assets/icons/coins/kcs.svg?url';
import MXN from 'src/assets/icons/coins/mxn.svg?url';
import UBET from 'src/assets/icons/coins/ubet.svg?url';
import USDC from 'src/assets/icons/coins/usdc.svg?url';
import USDT from 'src/assets/icons/coins/usdt.svg?url';

const COIN_SRC = {
  btc: BTC.src,
  eth: ETH.src,
  usdt: USDT.src,
  ape: APE.src,
  bnb: BNB.src,
  kcs: KCS.src,
  usdc: USDC.src,
  cad: CAD.src,
  brl: BRL.src,
  mxn: MXN.src,
  ubet: UBET.src,
};

export const getIconSrc = (symbol?: string) => {
  return _.get(COIN_SRC, [symbol?.toLowerCase() ?? 'btc'], undefined) as
    | string
    | undefined;
};

export const weights: Array<keyof typeof COIN_SRC> = [
  'btc',
  'eth',
  'usdt',
  'bnb',
  'usdc',
  'cad',
  'ape',
  'kcs',
  'brl',
  'mxn',
  'ubet',
];

const COUNTRY_TO_FIAT_SYMBOL: Record<string, string> = {
  ca: 'cad',
  br: 'brl',
  mx: 'mxn',
};

export const getCountryFiat = (country?: string | null) => {
  return country && COUNTRY_TO_FIAT_SYMBOL[country.toLowerCase() || ''];
};
