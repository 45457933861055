import * as Sentry from '@sentry/nextjs';
import { atom } from 'jotai';
import { atomEffect } from 'jotai-effect';
import _ from 'lodash';
import moment from 'moment';
import {
  ActivationEmail,
  activationEmail,
  checkKYCStatus,
  enable2FA,
  getAccountInfo,
  getProfile,
  login,
  logout,
  register,
  remove2FA,
  resendVerifyEmail,
  setDefaultCoin,
} from 'src/api/auth';
import { DEFAULT_COIN } from 'src/utils/constants/common-constant';
import {
  DEFAULT_LOCALE,
  LOCALE_KEY,
  USER_FINGERPRINT,
  USER_JWT_KEY,
} from 'src/utils/constants/keys';
import { getResponseMsg } from 'src/utils/constants/response-code';
import { isAnyAdmin } from 'src/utils/helpers/auth';
import { clientCookie } from 'src/utils/helpers/headers/headers.client';
import { shutdownIntercom } from 'src/utils/helpers/intercom';
import {
  trackIdentify,
  trackLogin,
  trackLogout,
  trackRegistrationComplete,
} from 'src/utils/helpers/rudderstack';
import {
  IUpdateAccountLevel,
  KYCStatusKey,
  LoginForm,
  ProfileData,
  UserRegisterForm,
} from 'src/utils/types/auth';
import { Enable2FABody, Remove2FABody } from 'src/utils/types/setting';
import { clearAllBetSlipsAtom, rightDrawerAtom } from './bet-slip';
import { clearCasinoFavAtom, favGamesAtom } from './casino';
import { loginAtom, registerAtom, userGeolocationAtom } from './layout';
import { userSettingAtom } from './settings';
import { clearFavAtom, favSportsAtom, favTournamentsAtom } from './sport';
import { balancesAtom } from './wallet';

export const _accessTokenAtom = atom<string | null>(null);

export const accessTokenAtom = atom(
  (get) => get(_accessTokenAtom),
  (get, set, token: string | null, opts?: any) => {
    set(_accessTokenAtom, token);
    if (token) {
      clientCookie.set(USER_JWT_KEY, token, opts);
    }

    return token;
  },
);

export const _localeAtom = atom(DEFAULT_LOCALE);

export const localeAtom = atom(
  (get) => get(_localeAtom),
  async (get, set, locale?: string) => {
    const prev = locale ?? get(_localeAtom);
    set(_localeAtom, prev);

    // maxAge 30 day
    const maxAge = 30 * 24 * 60 * 60;

    clientCookie.set(LOCALE_KEY, prev, { maxAge });
    return prev;
  },
);

export const _profileAtom = atom<ProfileData | null>(null);

const _waitForLoggedAtom = atom<Array<(val: ProfileData) => any>>([]);

export const waitForLoggedAtom = atom(
  (get) => get(_waitForLoggedAtom),
  async (get, set, entry?: { register?: boolean; login?: boolean }) => {
    const profile = get(_profileAtom);
    if (profile) {
      return Promise.resolve(profile);
    }

    if (entry?.register) {
      set(registerAtom, true);
    } else if (entry?.login) {
      set(loginAtom, true);
    }

    return await new Promise<ProfileData>((resolve) => {
      set(_waitForLoggedAtom, [resolve]);
    });
  },
);

export const cleanUpLoggedWaiterAtom = atomEffect((get, set) => {
  const open = get(registerAtom) || get(loginAtom);

  if (!open) {
    set(_waitForLoggedAtom, []);
  }
});

export const profileAtom = atom(
  (get) => get(_profileAtom),
  async (get, set, opts?: { emitEvents?: boolean }) => {
    const { emitEvents = true } = opts || {};

    const data = await Promise.all([
      getProfile(),
      set(accountInfoAtom),
      set(kycAtom),
      set(balancesAtom),
      set(favGamesAtom),
      set(favSportsAtom),
      set(favTournamentsAtom),
      set(userSettingAtom),
    ]);

    const [res, accInfo] = data;

    Sentry.setUser({ email: res.email, username: res.username, id: res.id });

    if (emitEvents && !isAnyAdmin(res.role)) {
      trackIdentify({
        profile: res,
        accountInfo: accInfo,
        locale: get(localeAtom),
      });
    }

    get(_waitForLoggedAtom).forEach((fn) => fn(res));
    set(_waitForLoggedAtom, []);
    set(_profileAtom, res);

    return res;
  },
);

export const userIdAtom = atom((get) => get(profileAtom)?.id);

export const loggedAtom = atom((get) => {
  return !!get(_accessTokenAtom) && !_.isNil(get(userIdAtom));
});

export const blockAtom = atom(false);

const getUserFingerprint = async () => {
  try {
    const apiKey = process.env.NEXT_PUBLIC_FINGERPRINT_API_KEY;
    if (!apiKey) return;

    const loader = await import('@fingerprintjs/fingerprintjs-pro');

    const fp = await loader.load({
      apiKey,
    });
    if (!fp) return;

    return await fp.get({ extendedResult: true });
  } catch (err) {
    return;
  }
};

export type UserFingerprint = {
  visitorId: string;
  countryCode: string;
  countryName: string;
} | null;

export const _fingerprintAtom = atom<UserFingerprint>(null);

export const fingerprintAtom = atom(
  (get) => get(_fingerprintAtom),
  async (get, set) => {
    const fromCookie = get(_fingerprintAtom);
    if (fromCookie) return fromCookie;

    const fp = await getUserFingerprint();
    if (!fp) return;
    const payload = {
      visitorId: fp.visitorId,
      countryCode: fp.ipLocation?.country?.code ?? '',
      countryName: fp.ipLocation?.country?.name ?? '',
    };
    set(_fingerprintAtom, payload);
    clientCookie.set(USER_FINGERPRINT, JSON.stringify(payload));
    return payload;
  },
);

export const _kycAtom = atom(KYCStatusKey.IDL);
export const kycAtom = atom(
  (get) => get(_kycAtom),
  async (get, set, kyc?: KYCStatusKey) => {
    const _kyc = kyc ?? (await checkKYCStatus());
    set(_kycAtom, _kyc);
    return _kyc;
  },
);

export const logoutAtom = atom(null, (get, set) => {
  try {
    Sentry.setUser(null);
    const token = get(accessTokenAtom);
    logout(token);

    const userId = get(profileAtom)?.id;
    if (userId) {
      trackLogout(userId);
    }

    clientCookie.destroy(USER_JWT_KEY);
    set(clearFavAtom);
    set(clearCasinoFavAtom);
    set(_accessTokenAtom, null);
    set(_profileAtom, null);
    set(_accountInfoAtom, null);
    set(cancelDialogEmailExpiredAtom, false);

    set(rightDrawerAtom, null);
    set(clearAllBetSlipsAtom);
    shutdownIntercom();

    return true;
  } catch (err) {
    return false;
  }
});

export const enable2FAAtom = atom(
  null,
  async (get, set, body: Enable2FABody) => {
    const res = await enable2FA(body);
    set(_profileAtom, (prev) => prev && { ...prev, enabled2fa: true });
    return res;
  },
);

export const remove2FAAtom = atom(
  null,
  async (get, set, body: Remove2FABody) => {
    const res = await remove2FA(body);
    set(_profileAtom, (prev) => prev && { ...prev, enabled2fa: false });
    return res;
  },
);

export const _accountInfoAtom = atom<IUpdateAccountLevel | null>(null);

export const accountInfoAtom = atom(
  (get) => get(_accountInfoAtom),
  async (get, set) => {
    const res = await getAccountInfo();
    set(_accountInfoAtom, res);
    return res;
  },
);

export const doRegisterAtom = atom(
  null,
  async (get, set, body: UserRegisterForm) => {
    const { data } = await register(body);
    const ok = set(accessTokenAtom, data.access_token);
    ok && (await set(profileAtom));

    trackRegistrationComplete(get(userGeolocationAtom));

    return data;
  },
);

export const doLoginAtom = atom(
  null,
  async (get, set, loginData: LoginForm) => {
    const { code, data } = await login(loginData);
    if (code !== '00') {
      throw { code: '99', message: getResponseMsg('99') };
    }

    const maxAge = loginData.rememberMe
      ? moment(data?.expired_at ?? moment().add(1, 'day')).diff(
          Date.now(),
          'seconds',
        )
      : undefined;

    set(accessTokenAtom, data.access_token, { maxAge });
    await set(profileAtom, { emitEvents: true, maxAge });

    trackLogin();
    return { data: data, rememberMe: loginData.rememberMe };
  },
);

export const emailVerifiedAtom = atom(
  (get) => get(_profileAtom)?.emailVerified,
  (get, set, emailVerified: boolean) => {
    set(
      _profileAtom,
      (prev) => prev && { ...prev, emailVerified: emailVerified },
    );

    return emailVerified;
  },
);

export const emailVerifyExpireAtAtom = atom(
  (get) => get(_profileAtom)?.emailVerifyExpireAt,
  async (get, set) => {
    const emailVerifyExpireAt = await resendVerifyEmail();
    set(_profileAtom, (prev) => prev && { ...prev, emailVerifyExpireAt });

    return emailVerifyExpireAt;
  },
);

export const doActivationEmailAtom = atom(
  null,
  async (get, set, body: ActivationEmail) => {
    const { data } = await activationEmail(body);

    const ok = set(accessTokenAtom, data.accessToken);
    ok && (await set(profileAtom, { emitEvents: true }));

    return data;
  },
);

export const cancelDialogEmailExpiredAtom = atom(false);

export const stakeCoinAtom = atom(
  (get) => get(_profileAtom)?.selectedCoinSymbol || DEFAULT_COIN,
  async (get, set, symbol: string) => {
    set(
      _profileAtom,
      (prev) => prev && { ...prev, selectedCoinSymbol: symbol },
    );

    await setDefaultCoin(symbol);

    return symbol;
  },
);

export const changeUsernameAtom = atom(false);
