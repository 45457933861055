export const APP_NAME = 'UBET';

export const PROD_BASE_URL = 'https://ubet.io';
export const BASE_URL = process.env.NEXT_PUBLIC_BASE_URL ?? PROD_BASE_URL;

export const IS_REAL_PRODUCTION = BASE_URL === PROD_BASE_URL;

// server component so we hardcoded
export const BG_COLOR = '#011219';
export const PRIMARY_COLOR = '#0BA8F8';

export const BLOG_DATE_FORMAT = 'D/M/YY';

export const CONTENTFUL_REVALIDATE = 12 * 60 * 60; // 12h
